.noUi-tooltip {
	display: block;
	position: absolute;
	border: 1px solid #D9D9D9;
	border-radius: 3px;
	background: #fff;
	padding: 5px;
	text-align: center;
}

.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
	top: -32px;
}
.noUi-horizontal .noUi-handle-upper .noUi-tooltip {
	bottom: -32px;
}
.noUi-vertical .noUi-handle-lower .noUi-tooltip {
	left: 120%;
}
.noUi-vertical .noUi-handle-upper .noUi-tooltip {
	right: 120%;
}
